.preview-image {
  cursor: pointer;
}

.select2-selection--is-disabled  {
  .select2-selection__control--is-disabled {
    background-color: #eff2f7;
    .select2-selection__single-value--is-disabled {
      color: #495057;
    }
  }
}

.date-picker-isEable {
  background-color: #ffffff !important;
  
  .form-control {
    background-color: #ffffff !important;
  }
}

.html-editor {
  min-height: 462px;
  margin-bottom: 8px;

  .quill  {

    .ql-toolbar.ql-snow {
      border: 1px solid #ced4da;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }


    .ql-container.ql-snow {
      min-height: 400px;
      max-height: 1000px;
      overflow-y: auto;
      border: 1px solid #ced4da;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;

      .ql-editor {
        min-height: 400px;
      }
    }

    .ql-tooltip {
      left: 0!important;
    }

    .ql-snow {
      .ql-picker {
          &.ql-size {
              .ql-picker-label,
              .ql-picker-item{
                  &::before{
                    content: attr(data-value) !important;
                  }
              }
          }
          &.ql-lineHeight {
            width: 98px;

            .ql-picker-label, .ql-picker-item {
              &::before {
                content: attr(data-value) !important;
              }
              &[data-value="1"] {
                &::before {
                  content: '單行' !important;
                }
              }
              &[data-value="2"] {
                &::before {
                  content: '雙行' !important;
                }
              }
            }
          }
      }
    }
  }

  &.dark {
    .quill {
      .ql-container {
        background: #202124;
      }
    }
  }
}